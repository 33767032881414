"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const gcd = require('compute-gcd');
const lcm = require('compute-lcm');
class RatiocalculatorCtrl {
    constructor($scope, $timeout, $filter, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.kind = JSON.parse(localStorage.getItem(`${this.constructor.name}_kind`) || 'null') || 'find';
        this.kinds = {
            find: {
                key: 'find',
                label: this.$filter('translate')('Find a ratio equivalent'),
                transform: (data) => {
                    if (!data.c && !data.b) {
                        const val = gcd(data.a, data.b);
                        if (val == 1) {
                            return {
                                a: data.a,
                                b: data.b,
                                c: data.a * 2,
                                d: data.b * 2,
                            };
                        }
                        else {
                            return {
                                a: data.a,
                                b: data.b,
                                c: data.a / val,
                                d: data.b / val
                            };
                        }
                    }
                    else if (!data.a && ![data.b, data.c, data.d].some((item) => item == undefined)) {
                        return {
                            a: (data.c / data.d) * data.b,
                            b: data.b,
                            c: data.c,
                            d: data.d
                        };
                    }
                    else if (!data.b && ![data.a, data.c, data.d].some((item) => item == undefined)) {
                        return {
                            a: data.a,
                            b: (data.d / data.c) * data.a,
                            c: data.c,
                            d: data.d
                        };
                    }
                    else if (!data.c && ![data.b, data.a, data.d].some((item) => item == undefined)) {
                        return {
                            a: data.a,
                            b: data.b,
                            c: (data.a / data.b) * data.d,
                            d: data.d
                        };
                    }
                    else if (!data.d && ![data.b, data.a, data.c].some((item) => item == undefined)) {
                        return {
                            a: data.a,
                            b: data.b,
                            c: data.c,
                            d: (data.b / data.a) * data.c
                        };
                    }
                },
            },
            makeLarger: {
                key: 'makeLarger',
                label: this.$filter('translate')('Make a ratio larger'),
                transform: (data) => {
                    return {
                        a: data.a,
                        b: data.b,
                        c: data.a * data.c,
                        d: data.b * data.c,
                    };
                },
            },
            makeSmaller: {
                key: 'makeSmaller',
                label: this.$filter('translate')('Make a ratio smaller'),
                transform: (data) => {
                    return {
                        a: data.a,
                        b: data.b,
                        c: data.a / data.c,
                        d: data.b / data.c,
                    };
                },
            },
            ratio: {
                key: 'ratio',
                label: this.$filter('translate')('Simplify a ratio'),
                transform: (data) => {
                    const gcf = gcd([data.a, data.b]);
                    return {
                        a: data.a,
                        b: data.b,
                        c: data.a / gcf,
                        d: data.b / gcf,
                    };
                },
            },
            'ratio_1:n': {
                key: 'ratio_1:n',
                label: this.$filter('translate')("Simplify a ratio (1:n)"),
                transform: (data) => {
                    const gcf = gcd([data.a, data.b]);
                    return {
                        a: data.a,
                        b: data.b,
                        c: 1,
                        d: data.b / data.a,
                    };
                },
            },
            'ratio_n:1': {
                key: 'ratio_n:1',
                label: this.$filter('translate')("Simplify a ratio (n:1)"),
                transform: (data) => {
                    const gcf = gcd([data.a, data.b]);
                    return {
                        a: data.a,
                        b: data.b,
                        c: data.a / data.b,
                        d: 1,
                    };
                },
            }
        };
        this.hasError = false;
        this.data = {
            a: null,
            b: null,
            c: null,
            d: null,
        };
    }
    setKind(kind) {
        this.kind = kind;
        this.clear(this.ratioForm);
        localStorage.setItem(`${this.constructor.name}_kind`, JSON.stringify(kind));
    }
    $onInit() {
    }
    submitForm($form) {
        this.result = {};
        this.hasError = false;
        if ($form.$valid) {
            const result = this.kinds[this.kind].transform(this.data);
            if (result != undefined) {
                this.result = result;
            }
            else {
                this.hasError = true;
            }
        }
    }
    hasSomeUndefined(data) {
        console.log(Object.values(data || {}));
        return Object.values(data || {}).some((item) => item == null);
    }
    clear($form) {
        if ($form) {
            $form.$setPristine();
            $form.$setUntouched();
        }
        this.result = undefined;
        this.data = {
            a: null,
            b: null,
            c: null,
            d: null,
        };
    }
}
RatiocalculatorCtrl.$inject = ['$scope', '$timeout', '$filter', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameRatiocalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: RatiocalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('ratiocalculator/');
    }]);
